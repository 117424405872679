



































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import EntityType from '@/utils/enums/EntityType';
import SpeakerPageHeaderComponent from '@/components/page-header/SpeakerPageHeaderComponent.vue';
import ProductPageHeaderComponent from '@/components/page-header/ProductPageHeaderComponent.vue';
import DealPageHeaderComponent from '@/components/page-header/DealPageHeaderComponent.vue';
import ArticlePageHeaderComponent from '@/components/page-header/ArticlePageHeaderComponent.vue';
import SessionPageHeaderComponent from '@/components/page-header/SessionPageHeaderComponent.vue';
import ChannelPageHeaderComponent from '@/components/page-header/ChannelPageHeaderComponent.vue';
import TopicPageHeaderComponent from '@/components/page-header/TopicPageHeaderComponent.vue';
import ExhibitorPageHeaderComponent
  from '@/components/page-header/ExhibitorPageHeaderComponent.vue';
import CommunityUserPageHeaderComponent
  from '@/components/page-header/CommunityUserPageHeaderComponent.vue';
import UiPageWidget from '@/models/graphql/UiPageWidget';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import CommunityUser from '@/models/graphql/CommunityUser';
import ActionButtonGroupComponent from '@/components/action-buttons/ActionButtonGroupComponent.vue';
import SubEditionPageHeaderComponent
  from '@/components/page-header/SubEditionPageHeaderComponent.vue';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import ActionButtonType from '@/utils/enums/ActionButtonType';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    SpeakerPageHeaderComponent,
    ProductPageHeaderComponent,
    DealPageHeaderComponent,
    SessionPageHeaderComponent,
    ArticlePageHeaderComponent,
    ChannelPageHeaderComponent,
    ExhibitorPageHeaderComponent,
    SubEditionPageHeaderComponent,
    CommunityUserPageHeaderComponent,
    ActionButtonGroupComponent,
    TopicPageHeaderComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class GenericPageHeaderComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'GenericPageHeaderWidgetStore';

  @Prop({ required: false, default: false })
  readonly displayHall!: boolean;

  @Prop({ required: false, default: false })
  readonly displayBooth!: boolean;

  @Prop({ required: false, default: null })
  readonly route!: string;

  @Prop({ required: false })
  readonly schemaCode!: string;

  @Prop({ required: false, default: true })
  readonly displayFollowerCountBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayLiveBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayInterestedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayExpirationBadge!: boolean;

  @Prop({ required: false, default: null })
  protected readonly widget!: UiPageWidget;

  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @Prop({ required: false, default: null })
  private readonly type!: string;

  @Prop({ required: false, default: null })
  private data!: Data;

  @Prop({ required: false, default: () => [] })
  private readonly actionButtons!: Data[];

  private actionButtonsLocal = this.actionButtons;

  private EntityType = EntityType;

  private renderActionButtonsGroup = 0;

  private get articleSubtitle(): string {
    if ((this.type === 'article' || this.type === 'magazineArticle')
      && this.data && this.data.subtitle) {
      return this.data.subtitle as unknown as string;
    }
    return '';
  }

  private get isProfileVisible(): boolean {
    const typename = this.data.__typename as unknown as string;
    if (typename !== EntityType.USER) return true;

    const user = CommunityUser.hydrate(this.data);
    return user.isProfileVisible;
  }

  private get hasConnection(): boolean {
    const user = CommunityUser.hydrate(this.data);
    return !!user._ourConnection
      && (user._ourConnection.linkState === ConnectionStatusType.ACCEPTED
        || user._ourConnection.linkState === ConnectionStatusType.INVITED);
  }

  private get uid(): string {
    let r = this.entityCode;
    const matches = this.entityCode.match(/(%[a-zA-Z-_]+%)/gs);
    if (matches) {
      matches.forEach((m) => {
        const prop = m.replaceAll('%', '').trim();
        r = r.replaceAll(m, this.$route.params[prop]);
      });
      return r;
    }
    return this.entityCode;
  }

  private static validateURL(url: string): string {
    if (url.startsWith('https://') || url.startsWith('http://') || url.startsWith('//')) {
      return url;
    }
    return `//${url}`;
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('actionButtons')
  updateActionButtons(): void {
    this.actionButtonsLocal = this.actionButtons;
  }

  private updateConnectActionButton(communityUserConnection: CommunityUserConnection): void {
    const index = this.actionButtonsLocal.findIndex((a) => a.actionType === 'connect');
    if (index > -1 && this.actionButtonsLocal[index].actionResult) {
      this.$set(this.actionButtonsLocal[index].actionResult as CommunityUser, '_ourConnection', communityUserConnection);
    }
    this.renderActionButtonsGroup += 1;
  }

  private updateActionButtonProductUrl(elementButtons: Data[]): Data[] {
    elementButtons.forEach((elementButton) => {
      if (elementButton.actionType === ActionButtonType.URL
        || elementButton.actionType === ActionButtonType.LARGE_PRODUCT_URL) {
        const url = this.data.url ? this.data.url.toString() : null;
        if (url) {
          elementButton.route = GenericPageHeaderComponent.validateURL(url);
        }
        elementButton.label = 'actions.website';
        elementButton.icon = 'far fa-arrow-up-right';
      }
    });
    return elementButtons;
  }
}
